export const enum CHECKERBOARD_CELL_TYPE {
  CATEGORY = 'category',
  RATE = 'rate',
  ACCOMMODATION = 'accommodation',
  QUOTA = 'quota',
}

export const enum PRICE_FILTER {
  RATES = 'rates',
  ROOMS = 'rooms',
}

export interface CheckerboardState {
  dates: {
    date_from: string;
    date_to: string;
  };
  settingsDates: {
    date_from: string | null;
    date_to: string | null;
    isSelect: boolean;
  };
  editableData: {
    cellType?: CHECKERBOARD_CELL_TYPE;
    cell: (unknown & { id: string }) | null;
    isSpreading: boolean;
    daysInterval: string[];
    placeType?: string;
    placeNumber?: string;
    rateName?: string;
    categoryName?: string;
    newValue?: string | null;
    cellValue: Record<string, unknown> | null;
    oldValue?: string;
  };
  confirmModal: {
    type: CHECKERBOARD_CELL_TYPE | null;
    isShow: boolean;
  };
  dependentModal: {
    isShow: boolean;
  };
  controlButtons: {
    isShow: boolean;
  };
  selectedElements: unknown[];
  filters: {
    rooms: number[] | null;
    rates: number[] | null;
  };
  isUpdateInProcess: boolean;
}

export enum CheckerboardDates {
  date_from = 'date_from',
  date_to = 'date_to',
}

export const UNPUBLISHED_MODAL_OPENED = 'unpublished_modal_opened';

import { Grid, Link, Typography } from '@mui/material';
import {
  getYearsInterval,
  LAYOUT_ROUTES,
  privacy_policy_url,
  rem,
} from '@otello/helpers';
import { matchRoutes, useLocation } from 'react-router-dom';

import { RootWrapper } from '../index.styles';

import { Root } from './Footer.styles';

export const FooterLayout = () => {
  const { pathname } = useLocation();

  const isLayoutPages = !!matchRoutes(LAYOUT_ROUTES, pathname)?.length;

  return (
    <Root isPrices={false}>
      <RootWrapper>
        <Grid
          container
          gap={rem(16)}
          p={{ xs: rem(20, 20, isLayoutPages ? 20 : 70, 20) }}
          flexDirection={{ xs: 'column', md: 'row-reverse' }}
          justifyContent="space-between"
          height="100%"
          alignItems={{ md: 'center' }}
        >
          <Grid item>
            <Link
              target="_blank"
              rel="noopener,noreferrer"
              href={privacy_policy_url}
              typography="footnote"
              color="basic.secondary"
              variant="caption"
              underline="hover"
            >
              Политика конфиденциальности
            </Link>
          </Grid>
          <Grid item>
            <Typography color="basic.secondary" variant="p3">
              &#169;&nbsp;
              {getYearsInterval(
                new Date(2023, 0).getFullYear(),
                new Date().getFullYear(),
              )}
              , 2ГИС
            </Typography>
          </Grid>
        </Grid>
      </RootWrapper>
    </Root>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMonths, format, startOfWeek } from 'date-fns';
import { ru } from 'date-fns/locale';

import { CheckerboardDates, CheckerboardState, PRICE_FILTER } from './types';

const date_from =
  sessionStorage.getItem(CheckerboardDates.date_from) ??
  format(startOfWeek(new Date(), { locale: ru }), 'yyyy-MM-dd');
const date_to =
  sessionStorage.getItem(CheckerboardDates.date_to) ??
  format(addMonths(startOfWeek(new Date()), 3), 'yyyy-MM-dd');

const filterRooms = sessionStorage.getItem(PRICE_FILTER.ROOMS);
const filterRates = sessionStorage.getItem(PRICE_FILTER.RATES);

export const days: {
  id: string;
  name: string;
}[] = [
  {
    id: 'Mon',
    name: 'Пн',
  },
  {
    id: 'Tue',
    name: 'Вт',
  },
  {
    id: 'Wed',
    name: 'Ср',
  },
  {
    id: 'Thu',
    name: 'Чт',
  },
  {
    id: 'Fri',
    name: 'Пт',
  },
  {
    id: 'Sat',
    name: 'Сб',
  },
  {
    id: 'Sun',
    name: 'Вс',
  },
];

const initialState: CheckerboardState = {
  dates: {
    date_from,
    date_to,
  },
  settingsDates: {
    date_from: null,
    date_to: null,
    isSelect: false,
  },
  editableData: {
    cell: null,
    isSpreading: false,
    daysInterval: [],
    cellValue: null,
  },
  confirmModal: {
    type: null,
    isShow: false,
  },
  dependentModal: {
    isShow: false,
  },
  controlButtons: {
    isShow: false,
  },
  selectedElements: [],
  filters: {
    rooms: filterRooms ? filterRooms.split(',').map((id) => Number(id)) : null,
    rates: filterRates ? filterRates.split(',').map((id) => Number(id)) : null,
  },
  isUpdateInProcess: false,
};

export const checkerboardSlice = createSlice({
  name: 'checkerboard',
  initialState,
  reducers: {
    setCheckerboardDates: (
      state,
      {
        payload: { dates },
      }: PayloadAction<{
        dates: CheckerboardState['dates'];
      }>,
    ) => {
      state.dates = dates;

      if (dates.date_from && dates.date_to) {
        sessionStorage.setItem(CheckerboardDates.date_from, dates.date_from);
        sessionStorage.setItem(CheckerboardDates.date_to, dates.date_to);
      }
    },
    setSettingsDates: (
      state,
      {
        payload: { dates },
      }: PayloadAction<{
        dates: CheckerboardState['settingsDates'];
      }>,
    ) => {
      state.settingsDates = dates;
    },
    setEditableData: (
      state,
      {
        payload: { data },
      }: PayloadAction<{
        data: CheckerboardState['editableData'];
      }>,
    ) => {
      state.editableData = {
        ...data,
      };
    },
    setConfirmModal: (
      state,
      {
        payload: { isShow, type },
      }: PayloadAction<CheckerboardState['confirmModal']>,
    ) => {
      state.confirmModal = { isShow, type };
    },
    setDependentModal: (
      state,
      {
        payload: { isShow },
      }: PayloadAction<CheckerboardState['dependentModal']>,
    ) => {
      state.dependentModal = { isShow };
    },
    setControlButtons: (
      state,
      {
        payload: { isShow },
      }: PayloadAction<CheckerboardState['controlButtons']>,
    ) => {
      state.controlButtons = { isShow };
    },
    setSelectedElements: (
      state,
      { payload }: PayloadAction<CheckerboardState['selectedElements']>,
    ) => {
      state.selectedElements = payload;
    },
    setIsUpdateInProcess: (
      state,
      { payload }: PayloadAction<CheckerboardState['isUpdateInProcess']>,
    ) => {
      state.isUpdateInProcess = payload;
    },
    setFilters: (
      state,
      {
        payload: { type, filters },
      }: PayloadAction<{
        type: PRICE_FILTER;
        filters: number[] | null;
      }>,
    ) => {
      state.filters[type] = filters;

      sessionStorage.setItem(type, filters?.join(',') ?? '');
    },
  },
});

export const {
  setCheckerboardDates,
  setSettingsDates,
  setEditableData,
  setConfirmModal,
  setControlButtons,
  setDependentModal,
  setSelectedElements,
  setFilters,
  setIsUpdateInProcess,
} = checkerboardSlice.actions;
